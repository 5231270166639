.error-page {
    text-align: center;
    padding: 50px;
    font-family: sans-serif;
  }
  
  .error-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .error-message {
    font-size: 18px;
    color: #888;
  }
  
  .error-detail {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .error-stack {
    font-family: monospace;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 30px;
  }
  
  .error-actions {
    margin-top: 30px;
  }
  
  .error-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  