:root {
    --box-shadow: 5px 5px 10px 10px rgba(101, 175, 10, 0.5);
    --bg-color: rgb(40, 47, 97);
  }

  /* Navbar Section */

.nav_bar {
color: yellow;
  opacity: .5;
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
} 

.nav_bar .nav_items {
  padding: 16px;
  font-size: 1.2rem;
  font-weight: bold;
}

.nav_bar .nav_items:hover {
  border-bottom: 3px solid yellow;
}

  /* Main Section */
  .bg-container{
    background-color: rgb(11, 26, 51);
    color: white;
    height: 100%;
  }
  
 .left {
    background-color: var(--bg-color);
    width: auto;
    text-align: center;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid yellow;
    box-shadow: var(--box-shadow);
  }
  
 .right .img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.right .img img {
    height: 270px;
    width: 270px;
    border: 5px solid yellow;
    border-radius: 100%;
    animation-name: superman;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
  
  @keyframes superman {
    0% {
      border-color: blue;
      transform: translate(0, 0px);
      box-shadow: 10px 10px 100px 50px blue;
    }
  
    25% {
      border-color: rgb(239, 244, 11);
      box-shadow: 10px 10px 100px 50px rgb(239, 244, 11);
    }
  
    50% {
      border-color: rgb(12, 12, 199);
      transform: translate(0, 10px);
      box-shadow: 10px 10px 100px 50px rgb(12, 12, 199);
    }
  
    75% {
      border-color: rgb(130, 217, 160);
      box-shadow: 10px 10px 100px 50px rgb(130, 217, 160);
    }
  
    100% {
      border-color: rgb(50, 55, 205);
      transform: translate(0, -0px);
      box-shadow: 10px 10px 100px 50px rgb(50, 55, 205);
    }
  }

  /* Experience Section */

  /* .ex {
    width: 900px;
  } */
  
  .ex-items {
    background-color: var(--bg-color);
    /* margin: 5px;
    padding: 30px;
    width: auto; */
    border-radius: 10px;
    border: 1px solid yellow;
    box-shadow: var(--box-shadow);
   
  }
  .left-img {
    background-color: var(--bg-color);
    /* width: auto; */
    text-align: center;
  
    border-radius: 10px;
    border: 1px solid yellow;
    box-shadow: var(--box-shadow);
  }

/* Skills */

.skills {
   width: auto;
}

 .items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* width: 800px; */
  margin: auto;
  /* background-color: yellow; */
}

 .items .item {
  /* width: calc(33.33%); */
  width: auto;
  background-color: black;
  margin: 20px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid yellow;
  box-shadow: var(--box-shadow);
}

 .items .item img {
  width: auto;
}