/* *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
} */

body{
    /* display: flex; */
    height: 100vh;
    /* justify-content: center; */
    /* align-items: center; */
    /* background: url(../img/bg2.jpg); */
    background-size: cover;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.h1{
    color: white;
}
/* .danceColor{
    color: blue;
} */
 /* .contiuneBtn{
    background-color: blue;
    color: brown;
 } */
.container{
    width: 100%;
    /* max-width: 650px; */
    /* background: rgba(0, 0, 0, 0.5); */
    padding: 28px;
    /* margin: 0 28px; */
    /* border-radius: 10px; */
    /* box-shadow: inset -2px 2px 2px white; */
    display: inline-block;
}
.dropDown{
    width: 600px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid black;
}
.form-title{
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 6px;
    color: white;
    text-shadow: 2px 2px 2px black;
    border-bottom: solid 1px white;
}

.main-user-info{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
}

.user-input-box:nth-child(2n){
    justify-content: end;
}

.user-input-box{
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
}

.user-input-box label{
    width: 95%;
    color: black;
    font-size: 20px;
    font-weight: 400;
    margin: 5px 0;
}

.user-input-box input{
    height: 40px;
    width: 95%;
    border-radius: 7px;
    outline: none;
    border: 1px solid grey;
    padding: 0 10px;
}

.gender-title{
    color:white;
    font-size: 24px;
    font-weight: 600;
    border-bottom: 1px solid white;
}

.gender-category{
    margin: 15px 0;
    color: white;
}

.gender-category label{
    padding: 0 20px 0 5px;
}

.gender-category label,
.gender-category input,
.form-submit-btn input{
    cursor: pointer;
}

.form-submit-btn{
    margin-top: 40px;
}

.form-submit-btn input{
    display: block;
    width: 100%;
    margin-top: 10px;
    font-size: 20px;
    padding: 10px;
    border:none;
    border-radius: 3px;
    color: rgb(209, 209, 209);
    background: rgba(63, 114, 76, 0.7);
}

.form-submit-btn input:hover{
    background: rgba(56, 204, 93, 0.7);
    color: rgb(255, 255, 255);
}

.user-input-box li{
    color: aliceblue;
    font-size: medium;
    font-weight: 600;
}


.tirmsCondition{
    background-color: gray;
    padding: 20px;
    border-radius: 15px;
  }

  .tirmsConditionBox{
    color: white;
  }
  .tirmsConditionBox li{
    display: flex;
    color: white;
    font-size: 30px;
  }
  .validationError{
    color: red;
  }
  .ruls{
    font-size: 40px;
    color: black;
  }
  .green{
    color: rgb(16, 133, 60);
    cursor: pointer;
  }
  .red{
    color: red;
    cursor: pointer;
  }

  .except{
    margin: 2rem;
    display: grid;
    color: black;
  }

 .hideTd td:last-child{
    /* display: none; */
    /* visibility: hidden; */
   color: white;
  }
  .input-box{
    cursor: pointer;
    font-size: large;
  }

  .top {
    font-size: 3rem;
    position: fixed;
    margin-left: 84rem;
    margin-top: 33rem;
    cursor: pointer;
  }

  td img{
   margin-left: 30px;
    width: 33px;
  }
@media(max-width: 600px){
    .container{
        min-width: 280px;
    }

    .user-input-box{
        margin-bottom: 12px;
        width: 100%;
    }

    .user-input-box:nth-child(2n){
        justify-content: space-between;
    }

    .gender-category{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .main-user-info{
        max-height: 380px;
        overflow: auto;
    }

    .main-user-info::-webkit-scrollbar{
        width: 0;
    }
}
